import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoinEnum, Quote } from '../models/coin';
import { AppThunk, RootState } from './index';
import { Socket, io as connect } from "socket.io-client";

export const delay = (time: number): Promise<void> =>
    new Promise<void>(resolve => setTimeout(resolve, time));

interface PriceState{
    lastQuotes: Record<CoinEnum, Quote>,
    quotes: Record<CoinEnum, Quote>
}

const initialState: PriceState = {
    lastQuotes: {} as Record<CoinEnum, Quote>,
    quotes: {} as Record<CoinEnum, Quote>
}

const priceSlice = createSlice({
    name: 'price',
    initialState,
    reducers: {
        updateQuote: (state, action: PayloadAction<Quote>) => {
            state.lastQuotes[action.payload.source.coin] = state.quotes[action.payload.source.coin];
            state.quotes[action.payload.source.coin] = action.payload;
        }
    }
});

const { updateQuote } = priceSlice.actions;

let wsConnection: Socket;
const url = process.env.REACT_APP_WEBSOCKET!;
export const subscribePriceQuotes = (): AppThunk => async dispatch => {
    wsConnection = connect(url, {
        path: '/api/websocket/socket',
        reconnectionDelay: 0,
        forceNew: true,
        transports: ['websocket'],
    });

    wsConnection.on('connect', () => {
        console.log(`WS: Connection to ${url} established`);
    });

    wsConnection.on('disconnect', () => {
        console.log(`WS: Connection to ${url} was closed`);
    });

    wsConnection.on('update-coin-price', (quote: Quote) => dispatch(updateQuote(quote)));
}

export const unsubscribeSocket = (): AppThunk => dispatch => {
    if (!wsConnection?.connected)
        return;

    wsConnection?.close();
}


export const getQuote = (coin: CoinEnum) => (root: RootState): Quote | undefined => root.price.quotes[coin];
export const getLastQuote = (coin: CoinEnum) => (root: RootState): Quote | undefined => root.price.lastQuotes[coin];

export default priceSlice.reducer;