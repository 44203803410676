import Logo from '../../assets/images/logo.png';
import Account from '../../assets/icons/account.svg';
import Hand from '../../assets/images/hand.png';
import Footer from '../../assets/images/footer.png';
import styles from './cover.module.scss';

export const Cover = () => {
    return (
        <div className={styles.cover}>
            <div className={styles.header}>
                <img className={styles.logo} alt='logo' src={Logo} />
                <div className={styles.menu}>
                    <a download href='https://grootbit-website-static.s3.sa-east-1.amazonaws.com/GrootBit_Info.pdf'>Sobre
                        Nos</a>
                    <a target='_self' href='/compliance/support'>Suporte</a>
                </div>
                <div className={styles.signIn}>
                    <a href='https://account.grootbit.com'>
                        <img src={Account} alt='' />
                        <span>Entrar</span>
                    </a>
                </div>
            </div>

            <div className={styles.body}>
                <div className={styles.singUp}>
                    <span className={styles.title}>Faça parte do <br /> time <span className={styles.grootbit}>Groot!</span></span>
                    <p><strong>Cresça com o mercado cripto.</strong> Crie sua conta gratuitamente e tenha acesso às
                        melhores ferramentas de análise para tomada de decisões no mercado cripto.</p>
                </div>
                <div className={styles.image}>
                    <img alt='hand' src={Hand} />
                </div>
            </div>

            <div className={styles.footer}>
                <img alt='footer' src={Footer} />
            </div>
        </div>
    )
}