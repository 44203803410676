import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { subscribePriceQuotes } from './priceSlice';
import price from './priceSlice';

export const store = configureStore({
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
    reducer: {
        price
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    AnyAction>;
export type AppDispatch = typeof store.dispatch;

export const setupApplication = (): void => {
    store.dispatch(subscribePriceQuotes())
};
