import { useCallback, useState } from "react";
import styles from './faq.module.scss';

const questions: { title: string, text: string }[] = [
    {
        title: 'Quem é a Grootbit?',
        text: 'Grootbit é uma corretora de criptomoedas atuando no Brasil com negociações em Bitcoin, Ethereum (ETH), USD Coin (USDC) e USD Theter (USDT).',
    },
    {
        title: 'Investimento e mercado cripto',
        text: 'Criptomoedas são moedas digitais criadas com protocolos específicos ao propósito pelo qual foram criadas que possibilitam transações seguras e resistentes à fraudes, tudo sem a necessidade de intermediários como bancos. A maior parte das criptomoedas são apoiadas pela tecnologia Blockchain, que funciona como um livro-razão, criptografando todas as transações registradas em um determinado período em blocos. Um dos motivos para se investir em criptomoedas, é que a adesão das moedas está aumentando exponencialmente. \n' +
            'Se em 1998 a internet tinha 1,5% de usuários globais, hoje é indispensável para a computação moderna, o que será das criptomoedas daqui a 10 anos, já que se encontram na mesma porcentagem de 1,5% de usuários mundiais com carteiras digitais? Temos que ter em mente que hoje, seguindo uma tendência histórica, as reservas de valores tendem a ser cada vez mais digitalizadas e descentralizadas, justamente para não sofrer com correções desleais do mercado financeiro tradicional. Desde o escambo, passando pela reserva de valor em moedas de metais, casas de custódia no lastro-ouro, papel moeda, cédulas e enfim a era digital, é necessário que nos adaptemos às transformações históricas para que nossa reserva de valor não seja afetada por tantos fatores externos.',
    },
    {
        title: 'Aplicativo',
        text: 'Faça o download na App Store caso você tenha um smartphone com iOS, e na Play Store, caso você tenha um smartphone Android.\n' +
            'Para realizar o cadastro, tenha em mãos o número do seu documento de identificação (CPF) e seu documento de identificação com foto (RG ou CNH). Por segurança, você será solicitado a tirar uma foto do seu documento para podermos atestar sua autenticidade. Informações como endereço de e-mail válido, número de celular e a criação de uma senha para login são solicitadas, juntamente com a autenticação de dois fatores (2FA). Mais informações sobre carteira digital, saques e depósitos, compra e venda de moedas, acesse nosso suporte.',
    },
    {
        title: 'Dispositivo',
        text: 'Pelo desenvolvimento da arquitetura dos produtos e para sua segurança, a Grootbit reserva a operação a dispositivos móveis (smartphones e tablets) para a melhor experiência do usuário desde o registro ao controle de suas operações. Isto só é possível utilizando diversos recursos tecnológicos que apenas um smartphone possui. Por exemplo, para o cadastro inicial, temos que acessar a câmera do seu smartphone. Além disso, outras funcionalidades importantes, como o monitoramento em tempo real das suas carteiras, só são possíveis com este tipo de aparelho.',
    },
];

export const FAQ: React.FC = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <div className={styles.faq}>
            <div className={styles.title}>
                <span>Ainda tem dúvidas?</span>
            </div>
            <div className={styles.menu}>
                {questions.map((question, index) => {
                    const styleActive = index === selectedIndex ? styles.active : '';
                    return (
                        <span className={`${styles.menuItem} ${styleActive}`} onClick={() => setSelectedIndex(index)} key={index}>
                            {question.title}
                        </span>
                    );
                })}
        </div>
    <div className={styles.information}>
        {questions[selectedIndex].text}
    </div>
    <div className={styles.dummyLineOverhead}></div>
    <div className={styles.dummyLineVertical}></div>
    <div className={styles.dummyLineBottom}></div>
</div>
)
}