import FastClockIcon from '../../assets/icons/fast-clock.png';
import ShieldIcon from '../../assets/icons/shield.png';
import WalletIcon from '../../assets/icons/wallet.png';
import PiggyBankIcon from '../../assets/icons/piggy-bank.png';
import TargetIcon from '../../assets/icons/target.png';
import ChartUpIcon from '../../assets/icons/chart_up.png';
import { DetailedInformation } from '../DetailedInformation';
import { useEffect, useState } from 'react';
import styles from './download-the-app.module.scss';
import LoginPageImage from '../../assets/images/login_page.png';
import MainPageImage from '../../assets/images/main_page.png';
import DepositPageImage from '../../assets/images/deposit_page.png';
import GooglePlayStore from '../../assets/images/google_play.png';
import AppleStore from '../../assets/images/apple_store.png';
import QrCodeAppStore from '../../assets/images/qrCodeAppStore.png';
import QrCodeGooglePlay from '../../assets/images/qrCodeGooglePlay.png';

export enum StoreEnum {
    googlePlay = 1,
    appStore = 2
}

export enum SectionEnum {
    time = 0,
    wallet = 1,
    target = 2
}

export const sectionEnums = [SectionEnum.time, SectionEnum.wallet, SectionEnum.target];

export const DownloadTheApp = () => {
    const [selectedSection, setSelectedSection] = useState(SectionEnum.time);
    const [selectedStore, setSelectedStore] = useState<StoreEnum>();

    useEffect(() => {
        const interval = setInterval(() => {
            const nextIndex = (selectedSection + 1) % sectionEnums.length;
            setSelectedSection(sectionEnums[nextIndex]);
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    }, [selectedSection]);

    const selectedImage = {
        [SectionEnum.time]: LoginPageImage,
        [SectionEnum.wallet]: MainPageImage,
        [SectionEnum.target]: DepositPageImage
    }[selectedSection];

    const qrCodeImage = {
        [0]: undefined,
        [StoreEnum.googlePlay]: QrCodeGooglePlay,
        [StoreEnum.appStore]: QrCodeAppStore
    }[selectedStore || 0];

    return (
        <div className={styles.downloadTheApp}>
            <div>
                <h2>Adquira suas criptomoedas</h2>
                <h3>Diversifique seus ganhos</h3>

                <DetailedInformation
                    primaryIcon={FastClockIcon}
                    secondaryIcon={ShieldIcon}
                    title="Em apenas 3 minutos"
                    text="Realize o seu cadastro no app de forma rápida e com a segurança da tecnologia 2FA"
                    isActive={selectedSection === SectionEnum.time}
                    onClick={() => setSelectedSection(SectionEnum.time)}
                />

                <DetailedInformation
                    primaryIcon={WalletIcon}
                    secondaryIcon={PiggyBankIcon}
                    title="Monte sua carteira digital"
                    text="Carregue sua carteira através do PIX, TED e também com criptomoedas"
                    isActive={selectedSection === SectionEnum.wallet}
                    onClick={() => setSelectedSection(SectionEnum.wallet)}
                />

                <DetailedInformation
                    primaryIcon={TargetIcon}
                    secondaryIcon={ChartUpIcon}
                    title="Defina sua estratégia"
                    text="Utilize as ferramentas de análise de mercado para tomar decisões arrojadas de investimento"
                    isActive={selectedSection === SectionEnum.target}
                    onClick={() => setSelectedSection(SectionEnum.target)}
                />
            </div>
            <div className={styles.downloadTheAppCallToAction}>
                {!!selectedStore && (
                    <div className={styles.scanQrCode}> Aponte a câmera do seu celular para o QR Code</div>
                )}

                <div className={styles.content}>
                    {!qrCodeImage && (
                        <img className={styles.image} src={selectedImage} alt="content image" />
                    )}

                    {qrCodeImage && (
                        <img
                            className={styles.qrCode}
                            src={qrCodeImage}
                            alt="app store button"
                        />
                    )}
                </div>
                <div className={styles.callToAction}>
                    <span>BAIXE JÁ O APP <b>GROOTBIT</b></span>
                    <div className={styles.actions}>
                        <img
                            src={GooglePlayStore}
                            alt="Google Play Store"
                            onClick={() => setSelectedStore(StoreEnum.googlePlay)} />

                        <img
                            src={AppleStore}
                            alt="Apple Store"
                            onClick={() => setSelectedStore(StoreEnum.appStore)} />
                    </div>
                </div>
            </div>
        </div>
    );
};