import React, { useEffect } from 'react';
import { Cover } from './components/Cover';
import { unsubscribeSocket } from './store/priceSlice';
import { useAppDispatch } from './hooks';
import { Quotes } from './components/Quotes';
import { DownloadTheApp } from './components/DownloadTheApp';
import { Mission } from './components/Mission';
import { FAQ } from './components/FAQ';
import { Footer } from './components/Footer';

function App() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(unsubscribeSocket());
        }
    }, []);
    return (
        <div>
            <Cover />
            <Quotes />
            <DownloadTheApp />
            <Mission />
            <FAQ />
            <Footer />
        </div>
    );
}

export default App;
