import TransparencyImage from '../../assets/images/transparencyImage.png';
import RespectImage from '../../assets/images/respectImage.png';
import AutonomyImage from '../../assets/images/autonomyImage.png';
import { CardImage } from '../CardImage';
import styles from './mission.module.scss';

export const Mission = () => {
    return (
        <div className={styles.mission} id="aboutUs">
            <div className={styles.texts}>
                <h2>Nossa missão</h2>
                <p className="text">
                    Nosso time está empenhado em transformar oportunidades do
                    mercado de criptomoedas em resultados que adicionem valor ao
                    patrimônio do investidor. Oferecemos oportunidades do mundo cripto tanto para o mercado brasileiro
                    quanto internacional.A Grootbit tem a missão de se posicionar entre as top 3 corretoras de
                    criptomoedas
                    no Brasil, assumindo um marketshare de pelo menos 20% na gestão de critpoativos do mercado.
                </p>
                <h2>Nossos valores</h2>
                <p className={styles.text}>
                    Na Groot, nossos valores estão focados na experiência do cliente. Aqui, você tem a transparência
                    sobre o
                    que acontece no mercado, a autonomia de decidir o que é melhor para o seu investimento e o respeito
                    dos
                    nossos colaboradores que estão empenhados em propor soluções para você vencer sempre!
                </p>
            </div>
            <div className={styles.images}>
                <div>
                    <CardImage image={TransparencyImage} title="Transparência" />
                </div>
                <div>
                    <CardImage image={RespectImage} title="Respeito" />
                    <CardImage image={AutonomyImage} title="Autonomia" />
                </div>
            </div>
        </div>
    )
}