import { FunctionComponent } from "react";
import styles from './card-image.module.scss';

interface Props {
    image: string;
    title: string;
}

export const CardImage: FunctionComponent<Props> = props => {
    return (
        <div className={styles.cardImage}>
            <div>
                <img src={props.image} alt="image" />
            </div>
            <div className={styles.title}>{props.title}</div>
        </div>
    )
}