import BtcIcon from '../assets/icons/btc.png';
import EthIcon from '../assets/icons/eth.png';
import UsdcIcon from '../assets/icons/usdc.svg';
import UsdtIcon from '../assets/icons/tether.svg';

export interface Amount {
    coin: CoinEnum;
    value: number;
}

export interface Quote {
    source: Amount;
    target: Amount;
}


export enum CoinEnum {
    real = 1,
    usdt = 2,
    btc = 3,
    usdc = 4,
    eth = 5,
    usd = 7
}

export interface CoinData {
    coin: CoinEnum;
    icon: string;
    color: string;
    label: string;
    initials: string;
}

export const allCoins: CoinData[] = [
    {
        coin: CoinEnum.btc,
        icon: BtcIcon,
        color: '#FFA800',
        label: 'Bitcoin',
        initials: 'BTC',
    },
    {
        coin: CoinEnum.eth,
        icon: EthIcon,
        color: 'black',
        label: 'Ethereum',
        initials: 'ETH',
    },
    {
        coin: CoinEnum.usdc,
        icon: UsdcIcon,
        color: '#647C95',
        label: 'USD Coin',
        initials: 'USDC',
    },
    {
        coin: CoinEnum.usdt,
        icon: UsdtIcon,
        color: 'green',
        label: 'Tether',
        initials: 'USDT',
    },
];

export enum Languages {
    ptBr = 'pt-br',
    enUs = 'en-us'
}

export const formatAmount = (amount: Amount): string => {
    const symbols: Record<CoinEnum, string> = {
        [CoinEnum.real]: 'R$',
        [CoinEnum.eth]: 'ETH',
        [CoinEnum.usd]: '$',
        [CoinEnum.usdc]: 'USDC',
        [CoinEnum.btc]: 'BTC',
        [CoinEnum.usdt]: 'USDT',
    };

    const symbol = symbols[amount.coin];

    if (!symbol)
        console.log(`formatAmount -> Symbol not fount for: ${amount.coin} coin`);

    const result = amount.value.toLocaleString(window.navigator.language);
    return `${symbol || ''} ${result}`;
};
