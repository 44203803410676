import { FunctionComponent } from "react";
import styles from './double-icon.module.scss';

interface Props {
    primaryIcon: string;
    secondaryIcon: string;
}

export const DoubleIcon: FunctionComponent<Props> = (props) => {
    return (
        <div className={styles.doubleIcon}>
            <div className={styles.primaryIcon}>
                <img src={props.primaryIcon} alt="primary icon" />
            </div>
            <div className={styles.secondaryIcon}>
                <img src={props.secondaryIcon} alt="secondary icon" />
            </div>
        </div>
    )
}