import { FunctionComponent } from "react";
import styles from './detailed-information.module.scss';
import { DoubleIcon } from '../DoubleIcon';

interface Props {
    primaryIcon: string;
    secondaryIcon: string;
    title: string;
    text: string;
    isActive: boolean;
    onClick: VoidFunction;
}

export const DetailedInformation: FunctionComponent<Props> = props => {
    const isActive = props.isActive ? styles.isActive : '';
    return (
        <div className={`${styles.detailedInformation} ${isActive}`} onClick={props.onClick}>
                <div className={styles.icon}>
                    <DoubleIcon primaryIcon={props.primaryIcon} secondaryIcon={props.secondaryIcon} />
                </div>
            <div className={styles.information}>
                <span className={styles.title}>{props.title}</span>
                <span className={styles.text}>{props.text}</span>
            </div>
        </div>
    )
}