import Logo from '../../assets/images/logo.png';
import FacebookIcon from '../../assets/icons/facebook.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import LinkedinIcon from '../../assets/icons/linkedin.svg';
import TwitterIcon from '../../assets/icons/twitter.svg';

import styles from './footer.module.scss';

const address = 'Av. das Nações Unidas, 14401 Torre C2 Tarumã, Conj. 303 - São Paulo - SP';
const addressLink = 'https://www.google.com/maps/place/GrootBit+-+Av+Na%C3%A7%C3%B5es+Unidas+,14401+Condom%C3%ADnio+Parque+da+Cidade+-+Torre+Tarum%C3%A3,+303+-+Ch%C3%A1cara+Santo+Ant%C3%B4nio+(Zona+Sul),+S%C3%A3o+Paulo+-+SP,+04794-000/@-23.6246634,-46.7022496,15z/data=!4m2!3m1!1s0x94ce51ae0ab070fb:0x7424080f54d1cb11?hl=pt-br';
const document = 'CNPJ 43.048.584/0001-88';

export const Footer = () => {

    return (
        <div className={styles.footer}>
            <div className={styles.logo}>
                <img src={Logo} alt="Logo grootbit" />
            </div>
            <a className={styles.address} href={addressLink}>{address}</a>
            <span className={styles.document}>{document}</span>
            <div className={styles.links}>
                <a href="#aboutUs">Sobre nós</a>
                <a href="#support">Suporte</a>
            </div>
            <div className={styles.icons}>
                <a href="https://www.facebook.com/GrootBit-106016338436681/" target="_blank" className="icon">
                    <img src={FacebookIcon} alt="Facebook icon" />
                </a>
                <a href="https://www.instagram.com/grootbit/" target="_blank" className="icon">
                    <img src={InstagramIcon} alt="Instagram icon" />
                </a>
                <a href="https://www.linkedin.com/company/grootbit" target="_blank" className="icon">
                    <img src={LinkedinIcon} alt="Linkedin icon" />
                </a>
                <a href="https://x.com/GrootBit" target="_blank" className="icon">
                    <img src={TwitterIcon} alt="Twitter icon" />
                </a>
            </div>
            <div className={styles.compliance}>
                <a href="/termsOfUse" target="_blank">Termos de uso</a> e <a href="/privacy" target="_blank">Política de
                Privacidade</a>
            </div>
        </div>
    );
};