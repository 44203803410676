import styles from './quotes.module.scss';
import { allCoins, CoinData, CoinEnum, formatAmount } from '../../models/coin';
import { FunctionComponent, useCallback, useState } from "react";
import { getLastQuote, getQuote } from '../../store/priceSlice';
import { useSelector } from 'react-redux';
import Decimal from 'decimal.js';

export const Quotes = () => {
    return (
        <div className={styles.quotes}>
            {allCoins.map((coin) => (
                <QuoteItem key={coin.coin} coinData={coin} />
            ))}
        </div>
    )
}

interface Props{
    coinData: CoinData;
}

const QuoteItem: FunctionComponent<Props> = ({coinData}) => {
    const currentQuote = useSelector(getQuote(coinData.coin));
    const lastQuote = useSelector(getLastQuote(coinData.coin));

    if(!currentQuote){
        return null;
    }

    const lastQuoteValue = lastQuote?.target.value || 0;

    const price = formatAmount(currentQuote.target);
    const rate = new Decimal(currentQuote.target.value)
        .times(100)
        .dividedBy(lastQuoteValue)
        .minus(100)
        .times(100)
        .floor()
        .dividedBy(100)
        .toNumber();

    const positiveRateStyle = rate > 0 ? styles.positive : '';
    const negativeRateStyle = rate < 0 ? styles.negative : '';
    return (
        <div className={styles.quoteItem}>
            <div className={styles.labels}>
                <div className={styles.icon} style={{ backgroundColor: coinData.color }}>
                    <img src={coinData.icon} alt="icons" />
                </div>
                <div className={styles.infos}>
                    <div className={`${styles.coinInitialsAndRate} ${positiveRateStyle} ${negativeRateStyle}`}>
                        <span className={styles.initials}>{coinData.initials}</span>
                        {rate !== 0 && (
                            <span className={styles.rate}>{rate}%</span>
                        )}
                    </div>
                    <div className={styles.labelCoin}>{coinData.label}</div>
                </div>
            </div>
            <div className={styles.price}>{price}</div>
        </div>
    )
}